$paragraphe-font-size: 16px;
$title-font-size: 40px;
$title-color: #2D117F;
$background-color: white;
$welcome-background-color: #37474f;
$footer-background-color: #b0bec5;

body, h1, h2, h3, h4, .mdl-button, .mdl-typography--display-2, .mdl-typography--display-2-color-contrast, .mdl-typography--display-1, .mdl-typography--display-1-color-contrast {
  font-family: Oxygen; }

.site-title {
  color: $title-color;
  text-decoration: none; }

.presentation {
  font-size: 22px;

  img {
    width: 100%; }

  iframe {
    margin-top: 20px; }

  a:hover {
    color: dodgerblue; } }

.precision {
  font-size: 18px;

  margin-bottom: 20px; }


.run, .contact {
  margin-top: 20px;
  text-align: center;
  width: 400px; }


p {
  font-size: $paragraphe-font-size !important; }

.menu-navigation .mdl-navigation__link:hover {
  color: $title-color !important; }

.navigation-selected {
  color: $title-color !important; }

h1 {
  font-size: $title-font-size; }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe, object, embed {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; } }

img.article-image {
  width: 100%;
  height: auto; }

.custom-header {
  overflow: visible;
  background-color: $background-color; }

#livre {

  .link {
    text-align: center; }

  img {
    width: 300px;
    perspective: 1000px; }

  h1 {
    font-size: 24px; }
  //line-height: 0

  h2 {
    display: inline !important;
    font-size: 22px;
    line-height: 14px; }

  h3 {
    display: inline !important;
    font-size: 20px;
    text-align: right; }

  p {
    text-align: justify; } }


.book_review {
  p {
    font-style: italic; }

  div {
    font-size: 20px; } }

.welcome-section {
  background: url('images/europe.jpg') no-repeat fixed center center;
  background-size: cover;
  height: 450px;
  @media screen and (max-width: 900px) {
    height: 650px; }

  h1 {
    padding-top: 160px;
    text-align: center;
    color: white !important;
    font-weight: bold; }

  h2 {
    text-align: center;
    color: white !important;
    font-weight: bold;
    font-style: italic;
    font-size: 28px; }
  a {
    color: white !important; } }



.welcome-band {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: $welcome-background-color; }

.welcome-band-text {
  max-width: 800px;
  margin-left: 25%;
  padding: 24px;
  text-align: center;
  color: white;

  p {
    padding-top: 8px; }

  @media screen and (max-width: 900px) {
    margin-left: 0;
    padding: 16px; } }

.custom-header {
  .mdl-navigation__link {
    color: #757575;
    font-weight: 700;
    font-size: 16px; }

  .material-icons {
    color: #767777 !important; } }

.menu-drawer-separator {
  height: 1px;
  background-color: #dcdcdc;
  margin: 8px 0; }

.menu-drawer {
  border-right: none;

  .mdl-navigation__link.mdl-navigation__link {
    font-size: 14px;
    color: #757575; }

  span.mdl-navigation__link.mdl-navigation__link {
    color: $title-color; }

  .mdl-layout-title {
    position: relative;
    background: $title-color;
    height: 80px;
    color: white;
    text-decoration: none; }

  .android-logo-image {
    position: absolute;
    bottom: 16px; } }

.welcome-subsection {
  text-align: center; }

.welcome-subsection-text {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 16px 0 16px;

  p {
    padding-top: 16px; } }

.welcome-subsection-image {
  background: url('images/moisson.jpg') center top no-repeat;
  background-size: cover;
  height: 400px; }

.more-section {
  padding: 10px 0;
  max-width: 1044px;
  margin-left: auto;
  margin-right: auto;

  .custom-section-title {
    margin-left: 12px;
    font-weight: bold; } }

.gradient-line {
  margin-top: 40px;
  margin-bottom: 20px;
  display: block;
  border: none;
  height: 1px;
  background: $title-color;
  background: linear-gradient(to right, white, white, $title-color, black, $title-color, white, white); }


.custom-link {
  text-decoration: none;
  color: $title-color !important;
  hover: {
    color: #7cb342 !important; }

  .material-icons {
    position: relative;
    top: -1px;
    vertical-align: middle; } }

.custom-card-container {
  .mdl-card__media {
    overflow: hidden;
    background: transparent; }

  .mdl-card__media img {
    width: 100%; }

  .mdl-card__title {
    background: transparent;
    height: auto; }

  .mdl-card__title-text {
    color: black;
    height: auto; }

  .mdl-card__supporting-text {
    height: auto;
    color: black;
    padding-bottom: 56px; }

  .mdl-card__actions {
    position: absolute;
    bottom: 0; }

  .mdl-card__actions {
    a {
      border-top: none;
      font-size: 16px; } } }

.friends {
  .mdl-card__title {
    height: 256px; }

  .mdl-card__title-text {
    font-weight: 500;
    color: white;
    text-shadow: 2px 1px 1px black;
    height: auto; }

  .mdl-card__supporting-text {
    height: auto;
    padding-bottom: 48px; }

  .mdl-card__actions {
    position: absolute !important;
    bottom: 0; }

  .material-icons {
    font-size: 30px;
    margin: 3px; } }

.menu-navigation-container {
  @media screen and (max-width: 900px) {
    display: none; } }

.mdl-tabs__tab {
  font-size: 18px; }

.mdl-tabs__tab-bar {
  border-bottom: 0px; }

.site-title {
  font-size: 30px; }

.description {
  font-size: 16px; }

ul {
  font-size: 16px; }

.center {
  text-align: center; }

.container {
  //width: 300px

  img {
    max-height: 100%;
    max-width: 100%; } }

.workers-presentation {
  font-size: 18px;
  line-height: 22px; }

.workers-role {
  margin: 12px 0;
  font-size: 24px;

  a {
    color: #757575; } }

.workers-rolesubtitle {
  font-size: 20px !important; }

.challenge-title {
  font-size: 18px;
  font-weight: 600; }

.challenge {
  font-size: 16px; }

.mdl-button--accent.mdl-button--accent {
  background-color: $title-color !important; }

.implication-link {
  text-align: center;
  margin-top: 20px;

  a {
    font-size: 32px;
    text-decoration: none;
    color: black; }

  a:hover {
    color: $title-color; }

  img {
    margin-top: 20px;
    height: 38px; } }

.mdl-card__actions {
  img {
    height: 26px; } }

.address {
  font-size: 18px;
  list-style: none; }

.contact {
  img {
    height: 28px; } }

.donner {
  p {
    font-size: 24px !important;
    line-height: 32px; }

  li {
    font-size: 24px;
    line-height: 32px; } }

.nobullet {
  li {
    list-style-type: none; } }

.lead {
  color: $title-color;
  font-size: 28px;
  font-weight: bold; }

a {
  color: black;
  text-decoration: none; }

a:hover {
  color: $title-color; }

footer {
  a {
    color: #e0e0e0; } }

.material-icons {
  vertical-align: middle; }

.language-icon {
  height: 32px; }

.meuropelogo {
  margin-left: 20px;
  height: 120px; }

.meditations-title {
  color: white !important;
  font-weight: 400;
  text-shadow: 2px 2px #000000; }

.scale:hover {
  .meditations-title {
    transform: translateY(20px) scale(1.1);
    transition: all 1s; } }

.clean {
  list-style: none; }
